import React from 'react'

import { ExternalFont } from '../external-font'

export function Fonts() {
  return (
    <>
      <ExternalFont href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Oswald:wght@700&family=Poppins:wght@400;600;700;800;900&display=swap" />
      <ExternalFont
        href="https://fonts.googleapis.com/css2?family=Indie+Flower&family=Inter:wght@300;400;700&family=Outfit:wght@900&display=swap"
        rel="stylesheet"
      />
      <ExternalFont
        href="https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap"
        rel="stylesheet"
      />
    </>
  )
}

// for @loadable/components
export default Fonts
